import { StatisticCardPanel } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { useStatisticTotalDataRequest } from '../../../const';
import { dict } from '@/hooks/useChangeLocale';

type ProdStatisticCardProps = {
  searchPrams: any;
};

export const ProdStatisticCardSimple = ({ searchPrams }: ProdStatisticCardProps) => {
  const { data: item, loading } = useStatisticTotalDataRequest(searchPrams);

  const items = [
    {
      title: dict('WORKS_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.total),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('PLATY_TOTAL'),
      value: Format.toAmountNumber(item?.playCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('LIKE_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.likeCount),
      suffix: dict('STATIST_UNIT_GE'),
      tip: dict('LIKE_NEGATIVE_NUM_TIP'),
    },
    {
      title: dict('SHARE_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.shareCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('COMMENT_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.commentCount),
      suffix: dict('STATIST_UNIT_TIAO'),
    },
  ];

  return <StatisticCardPanel items={items} columns={items.length} loading={loading} />;
};
