import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { VideoCoverImg } from '@/components/VideoCoverImg';
import { FAULT_IMAGE, ROUTE_PATH } from '@/constants/const';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getReportSummary } from '@/services/item';
import { Image, Space, Spin, Table, TableColumnType, Typography } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import { useNavigate } from 'umi';
import { VIDEO_NOTE_TYPE, VIDEO_NOTE_TYPE_NAME, convertDbFilterToFilter, getSoretParams } from '../../../const';
import { downloadXhsReportSummary } from '@/services/item/xhs';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { DateUtils } from '@/utils/dateUtil';
import { dict } from '@/hooks/useChangeLocale';

type ListPros = {
  params: any;
  type?: VIDEO_NOTE_TYPE;
};
const { Link } = Typography;

const List = ({ params, type }: ListPros) => {
  const navigate = useNavigate();
  const [downloadParams, setDownloadParams] = useSafeState<any>({});

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadXhsReportSummary(downloadParams), {
    manual: true,
  });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'publishTime', order: 'descend' } }) => {
      const { publish_time, ...rest } = params;
      const [startTime, endTime] = publish_time ?? [];
      const downloadParams = {
        startTime,
        endTime,
        noteType: type,
        ...getSoretParams(sorter),
        ...convertDbFilterToFilter(rest),
      };
      setDownloadParams(downloadParams);
      return getReportSummary({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, type], debounceWait: 100 },
  );

  const ROUTE_PRODUCTION_PATH = `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}`;

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW);

  return (
    <FormCard
      title={dict('LIST_WORKS')}
      style={{ marginBottom: 10 }}
      boxShadow={false}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        rowKey="itemId"
        scroll={{ x: 1200 }}
        columns={
          [
            getTableColumn({
              title: dict('COVER'),
              dataIndex: 'cover',
              width: 100,
              render: (url, record: any) =>
                record.noteType == VIDEO_NOTE_TYPE.NORMAL ? (
                  <Image
                    preview={false}
                    style={{ width: 80, borderRadius: 5 }}
                    fallback={FAULT_IMAGE}
                    referrerPolicy="same-origin"
                    src={record.coverOssUrl || url}
                  />
                ) : (
                  <VideoCoverImg
                    style={{ width: 80, borderRadius: 10 }}
                    itemId={record.itemId}
                    src={record.coverOssUrl || url}
                  />
                ),
            }),
            getTableColumn({
              title: dict('TITLE_OF_WORK'),
              dataIndex: 'title',
              width: 150,
              render: (value) => (
                <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>{value}</Typography.Paragraph>
              ),
            }),
            getTableColumn({
              title: dict('PUBLISH_ACCOUNT'),
              dataIndex: 'accountName',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.accountName} />,
            }),
            getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
            getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
            getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
            getTableColumn({
              title: dict('NOTE_TYPE'),
              dataIndex: 'noteType',
              sorter: true,
              render(noteType) {
                return VIDEO_NOTE_TYPE_NAME[noteType];
              },
            }),
            getTableColumn({ title: dict('VIEW_NUM'), dataIndex: 'playCount', sorter: true }),
            getTableColumn({ title: dict('COMMENT_TOTAL'), dataIndex: 'commentCount', sorter: true }),
            getTableColumn({ title: dict('LIKE_TOTAL'), dataIndex: 'likeCount', sorter: true }),
            getTableColumn({ title: dict('SHARE_TOTAL'), dataIndex: 'shareCount', sorter: true }),
            getTableColumn({ title: dict('COLLECTIONS_NUM'), dataIndex: 'fav', sorter: true }),
            type == VIDEO_NOTE_TYPE.NORMAL
              ? null
              : getTableColumn({ title: dict('BULLET_COMMENTS_NUM'), dataIndex: 'danmaku', sorter: true }),
            type == VIDEO_NOTE_TYPE.NORMAL
              ? null
              : getTableColumn({
                  title: dict('AVG_WATCH_TIME'),
                  dataIndex: 'avgPlayTimeSec',
                  sorter: true,
                  width: 150,
                  render: (_, record) => dict('NUM_SECOND', { num: record?.avgPlayTimeSec }),
                }),
            getTableColumn({ title: dict('INCREASING_FOLLOWERS'), dataIndex: 'followCount', width: 150, sorter: true }),
            getTableColumn({
              title: dict('PUBLISH_TIME'),
              dataIndex: 'publishTime',
              align: 'center',
              width: 120,
              sorter: true,
              defaultSortOrder: 'descend',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 130,
              render: (_, record: any) => {
                const url = `${ROUTE_PRODUCTION_PATH}/${ROUTE_PATH.XIAOHONGSHU}/${
                  ROUTE_PATH.DETAIL
                }/${encodeURIComponent(record.itemId)}`;

                return (
                  <Space>
                    <a
                      title={dict('ACTION_VIEW_INTERACTIVE')}
                      {...viewAuthConfig}
                      onClick={() => navigate(`${url}?type=video-detail`)}
                    >
                      {dict('ACTION_VIEW')}
                    </a>
                    <a
                      title={dict('ACTION_VIEW_COMMENT')}
                      {...viewAuthConfig}
                      onClick={() => navigate(`${url}?type=comment`)}
                    >
                      {dict('COMMENT')}
                    </a>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableColumnType<any>[]
        }
      />
    </FormCard>
  );
};

export default List;
