import Text from '@/components/Form/Field/Text';
import PoiSetInput from '@/components/Form/Field/poi/RegionPoiModal';
import { OrgAccountTreeSelect } from '@/components/Form/Field/select/OrgAccountTreeSelect';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';
import { columnConfig } from '@/pages/matrix-operation/publish/const';
import { ProductDetailContext } from '@/pages/matrix-operation/publish/detail/$id';
import { Card, Checkbox, Form, Input, TreeSelect, Typography } from '@antd';
import { useContext } from 'react';

type PlatformConfigContentProps = {
  opType: FormOpType;
};

export const WeixinConfigContent = ({ opType }: PlatformConfigContentProps) => {
  const readonly = opType == FormOpType.VIEW;
  const form = Form.useFormInstance();
  const plartformName = 'wxPublishReq';
  const accountId = Form.useWatch([plartformName, 'accountId'], form);
  const { activeRecordsTab } = useContext(ProductDetailContext);

  return (
    <Card title={dict('PLATFORM_SHIPINGHAO')} bordered size="small" style={{ marginTop: 10 }}>
      <Form.Item
        {...columnConfig}
        label={dict('PUBLISH_ACCOUNT')}
        name={[plartformName, 'accountId']}
        required
        rules={[{ required: true }]}
      >
        {readonly ? (
          <Typography.Link onClick={activeRecordsTab}>
            {dict('SEND_ACCOUNT_NUM_TIP', { num: accountId?.length })}
          </Typography.Link>
        ) : (
          <OrgAccountTreeSelect
            multiple
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            filterMediaIds={MEDIA_KEY_ENUM.WEIXIN}
          />
        )}
      </Form.Item>
      <Form.Item
        {...columnConfig}
        label={dict('WORK_INTRODUCTION')}
        name={[plartformName, 'caption']}
        required
        rules={[{ required: true, max: 1000 }]}
      >
        {readonly ? <Text /> : <Input.TextArea placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 1000 })} />}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        tooltip={dict('POSITON_SETTING_TIP')}
        label={dict('POI_POSITION')}
        name={[plartformName, '_poi']}
      >
        <PoiSetInput
          placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('POI_POSITION') })}
          readOnly={readonly}
          mediaId={MEDIA_KEY_ENUM.WEIXIN}
        />
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('VIDEO_TITLE')}
        tooltip={dict('VIDEO_TITLE_TIP')}
        name={[plartformName, 'title']}
        required
        rules={[{ required: true, max: 20 }]}
      >
        {readonly ? <Text /> : <Input placeholder={dict('VIDEO_TITLE_PLACEHOLDER')} />}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('ORIGINAL')}
        name={[plartformName, 'originalFlag']}
        valuePropName="checked"
        getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
        tooltip={dict('VIDEO_ORIGINAL_FIELD_TIP')}
      >
        <Checkbox disabled={readonly}>{dict('VIDEO_ORIGINAL_FIELD_TEXT_DESCRIBE')}</Checkbox>
      </Form.Item>
    </Card>
  );
};
//
