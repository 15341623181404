import { StatisticCardPanel } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { useStatisticTotalDataRequest } from '../../../const';
import { dict } from '@/hooks/useChangeLocale';

type ProdStatisticCardProps = {
  searchPrams: any;
  dateRangeValue?: string[];
};

export const ProdStatisticCardSimple = ({ searchPrams }: ProdStatisticCardProps) => {
  const { data: item, loading } = useStatisticTotalDataRequest(searchPrams);

  const items = [
    {
      title: dict('WORKS_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.total),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('PLATY_TOTAL'),
      value: Format.toAmountNumber(item?.playCount),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: dict('AVG_PLAY_DURATION'),
      value: Format.toAmountNumber((item?.avgPlayTimeSec || 0) / 60, 2),
      suffix: dict('STATIST_UNIT_MIN'),
    },
    {
      title: dict('LIKE_COUNT_TOAL'),
      value: Format.toAmountNumber(item?.likeCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('RECOMMEND_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.favCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('COMMENT_COUNT_NUM_TOTAL'),
      value: Format.toAmountNumber(item?.commentCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('FOLLOW_COUNT_TOTAL'),
      value: Format.toAmountNumber(item?.followCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('SHARE_COUNT_NUM_TOTAL'),
      value: Format.toAmountNumber(item?.shareCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
  ];

  return <StatisticCardPanel items={items} columns={items.length} loading={loading} />;
};
