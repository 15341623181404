import { App, Button, Flex, Space, Spin, Table, Typography } from '@antd';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { Blank } from '@/components/PageCard';
import { getPublishRecordPages, rePublishItems } from '@/services/item/publish';
import { useAppContext } from '@/contexts/AppContext';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useRequest } from 'ahooks';
import { PUBLISH_TASK_STATUS } from '../../const';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
const { Link } = Typography;

const List = ({ id }: { id }) => {
  const { message, modal } = App.useApp();
  const { user } = useAppContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { tableProps, refresh } = useTableRequest(
    async ({ pageIndex, pageSize }) => {
      return getPublishRecordPages({ pageIndex, pageSize, bid: user?.bid, id });
    },
    { refreshDeps: [user?.bid, id] },
  );

  const { loading: publishLoading, run: republish } = useRequest(
    async (id: number | null) => {
      try {
        await rePublishItems(id ? [id] : selectedRowKeys);
        setSelectedRowKeys([]);
        refresh();
        message.success(dict('OPERATION_SUCCESS'));
      } catch (error: any) {
        message.error(error.status?.message || dict('OPERATION_FAILURE'));
      }
    },
    { refreshDeps: [selectedRowKeys, refresh], manual: true },
  );

  const confirmRepublish = (id: number | null) => {
    modal.confirm({
      title: dict('CONFIRM_REPUBLISH_TIP'),
      content: dict('CONFIRM_REPUBLISH_CONTENT_TIP'),
      onOk: async () => republish(id),
    });
  };

  const { getBtnPermissionConfig } = usePermission();
  const publishAuthConfig = getBtnPermissionConfig(AUTH.MATRIX_OPERATION_PUBLISH.REPUBLISH);

  return (
    <>
      <Flex justify="space-between">
        <Button
          type="primary"
          loading={publishLoading}
          disabled={isEmpty(selectedRowKeys)}
          {...publishAuthConfig}
          onClick={() => confirmRepublish(null)}
        >
          {dict('ACTION_BATCH_PUBLISH')}
        </Button>
      </Flex>
      <Blank />

      <Table
        {...tableProps}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          checkStrictly: true,
          getCheckboxProps: (record) => ({
            disabled: record.publishStatus != PUBLISH_TASK_STATUS.FAILURE,
          }),
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        columns={[
          getTableColumn({
            title: dict('PLATFORM'),
            dataIndex: 'mediaName',
          }),
          getTableColumn({ title: dict('PUBOISH_TITLE'), dataIndex: 'title', ellipsis: true, width: 200 }),
          getTableColumn({ title: dict('PUBLISH_ACCOUNT'), dataIndex: 'accountName' }),
          getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
          getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
          getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
          getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
          getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
          getTableColumn({ title: dict('PUBLISH_FINISH_TIME'), dataIndex: 'publishFinishTime' }),
          getTableColumn({
            title: dict('PUBLISH_STATUS'),
            dataIndex: 'publishStatusRecordDisPlayName',
            // filters: PublishTaskStatusOptions.map(({ label, value }) => ({ text: label, value })),
          }),
          getTableColumn({
            title: dict('ACTION_OPERATE'),
            align: 'center',
            render(_, record) {
              const { id, publishStatus } = record;
              return (
                <Space>
                  <Spin spinning={publishLoading}>
                    <Link
                      disabled={publishStatus != PUBLISH_TASK_STATUS.FAILURE}
                      {...publishAuthConfig}
                      onClick={() => confirmRepublish(id)}
                    >
                      重新发布
                    </Link>
                  </Spin>
                </Space>
              );
            },
          }),
        ]}
      />
    </>
  );
};

export default List;
