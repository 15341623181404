import Text, { ArrLabelText, DateTimeText } from '@/components/Form/Field/Text';
import { Blank, FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM, MEDIA_MAPPING, datePickerFormItemConfig, defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { postPublishItemData, putPublishItemData } from '@/services/item/publish';
import { App, Button, Checkbox, DatePicker, Flex, Form, Input, Radio } from '@antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  PUBLISH_CONTENT_TYPE,
  PUBLISH_STATUS,
  PublishContentOptions,
  PublishTypeOptions,
  VIDEO_PUBLISTH_TYPE,
  handleSubmitData,
} from '../../const';
import { PictureCoverUpload, PictureFileUpload, VideoFileUpload } from './fields/Upload';
import { DouyinConfigContent } from './fields/douyin/Platform';
import { KuaishouConfigContent } from './fields/kuaishou/Platform';
import { WeixinConfigContent } from './fields/weixin/Platform';
import { XiaoHongShuConfigContent } from './fields/xiaohongshu/Platform';
import { useRequest } from 'ahooks';
import { Validator } from '@/utils/validator';
import { dict } from '@/hooks/useChangeLocale';
import { LocalesKeys } from '@/hooks/umi';
import { useAppContext } from '@/contexts/AppContext';

type FormContentProps = {
  opType: FormOpType;
  initialData: any;
};

const back = () => history.back();
const FormContent: React.FC<FormContentProps> = ({ opType, initialData }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { platforms } = useAppContext();
  const isViewType = opType == FormOpType.VIEW;

  const publishType = Form.useWatch('publishType', form);
  const contentType = Form.useWatch('contentType', form);
  const mediaIds = Form.useWatch('mediaId', form);
  const supportPlatforms = platforms.map((v) => v.id);

  //表单提交
  const { run: submitAction, loading: submitting } = useRequest(
    async (publishStatus: PUBLISH_STATUS) => {
      try {
        const datas = await form.validateFields();
        const submitDatas = handleSubmitData(datas);
        const submitAction = datas.id ? putPublishItemData : postPublishItemData;
        await submitAction({ ...submitDatas, publishStatus });
        message.success(dict('OPERATION_SUCCESS'));
        back();
      } catch (error: any) {
        if (!isEmpty(error.errorFields)) {
          const [{ name }] = error.errorFields;
          form.scrollToField(name);
        } else if (error?.status?.message) {
          message.error(error?.status.message);
        }
      }
    },
    { manual: true },
  );

  return (
    <Form
      form={form}
      {...defaultFormItemConfig}
      labelCol={{ span: isViewType ? 8 : 6 }}
      wrapperCol={{ span: isViewType ? 6 : 12 }}
      initialValues={initialData}
      requiredMark={!isViewType}
      onSubmitCapture={async () => {
        try {
          const datas = await form.validateFields();
          const submitDatas = handleSubmitData(datas);
          const submitAction = datas.id ? putPublishItemData : postPublishItemData;
          await submitAction(submitDatas);
          message.success(dict('OPERATION_SUCCESS'));
          back();
        } catch (error: any) {
          if (error?.status?.message) {
            message.error(error?.status.message);
          }
        }
      }}
    >
      <FormCard title={dict('BASIC_INFO')} bordered={isViewType}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="bid">
          <Input />
        </Form.Item>
        <Form.Item hidden name="tenantId">
          <Input />
        </Form.Item>

        <Form.Item label={dict('TASK_NAME')} name="name" required rules={[{ required: true, max: 50 }]}>
          {isViewType ? <Text /> : <Input placeholder={dict('PLEASE_TYPE')} />}
        </Form.Item>

        <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
          {isViewType ? <Text /> : <Input.TextArea placeholder={dict('PLEASE_TYPE')} />}
        </Form.Item>
      </FormCard>
      <Blank />
      <FormCard title={dict('CONTENT_SETTING')} bordered={isViewType}>
        <Form.Item label={dict('PUBLISH_TIME')} required>
          <Flex style={{ marginBottom: -20 }}>
            <Form.Item
              name="publishType"
              hidden={isViewType && publishType == VIDEO_PUBLISTH_TYPE.SCHEDULE}
              rules={[{ required: true }]}
            >
              {isViewType ? <ArrLabelText list={PublishTypeOptions} /> : <Radio.Group options={PublishTypeOptions} />}
            </Form.Item>

            {/* 定时发送时需要设置发布时间 */}
            {publishType == VIDEO_PUBLISTH_TYPE.SCHEDULE && (
              <Form.Item
                name="publishTime"
                rules={[
                  {
                    required: true,
                    message: dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('PUBLISH_TIME') }),
                  },
                  {
                    validator(_, value) {
                      if (value && dayjs().isAfter(value)) return Promise.reject(dict('PUBLISH_TIME_REQUIRED_TIP'));
                      return Promise.resolve();
                    },
                  },
                ]}
                {...datePickerFormItemConfig}
              >
                {isViewType ? (
                  <DateTimeText />
                ) : (
                  <DatePicker showTime disabledDate={(curDate) => dayjs().isAfter(curDate)} />
                )}
              </Form.Item>
            )}
          </Flex>
        </Form.Item>

        <Form.Item label={dict('CONTENT_TYPE')} name="contentType" required rules={[{ required: true }]}>
          {isViewType ? (
            <ArrLabelText list={PublishContentOptions} />
          ) : (
            <Radio.Group options={PublishContentOptions} onChange={() => form?.setFieldValue('filePath', [])} />
          )}
        </Form.Item>

        {contentType == PUBLISH_CONTENT_TYPE.VIDEO ? (
          <Form.Item
            label={dict('PUBLISH_VIDEO')}
            name="filePath"
            required
            rules={[
              {
                required: true,
                validator(_, value) {
                  if (Validator.isNilEmpty(value)) return Promise.reject(dict('PLEASE_UPLOAD_VIDEO'));
                  return Promise.resolve();
                },
              },
            ]}
            tooltip={dict('UPLOAD_VIDEO_TIP')}
          >
            <VideoFileUpload readonly={isViewType} />
          </Form.Item>
        ) : null}

        {contentType == PUBLISH_CONTENT_TYPE.PICTURE_TEXT ? (
          <Form.Item
            label={dict('UPLOAD_PIC_TEXT')}
            name="filePath"
            required
            rules={[
              {
                required: true,
                validator(_, value) {
                  if (Validator.isNilEmpty(value)) return Promise.reject(dict('PLEASE_UPLOAD_PIC_TEXT'));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PictureFileUpload readonly={isViewType} />
          </Form.Item>
        ) : null}

        <Form.Item label={dict('PUBLISH_COVER_IMG')} name="coverPath">
          <PictureCoverUpload readonly={isViewType} />
        </Form.Item>
      </FormCard>
      <Blank />
      <FormCard title={dict('PLATFORM_CONFIG')} bordered={isViewType}>
        <Form.Item
          label={dict('PLATFORM')}
          name="mediaId"
          required
          hidden={isViewType}
          rules={[{ required: true, message: dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('PLATFORM') }) }]}
        >
          <Checkbox.Group
            options={Object.values(MEDIA_MAPPING)
              .filter(([v]) => supportPlatforms.includes(Number(v)))
              .map(([value, i18nText]) => ({
                label: dict(i18nText as LocalesKeys),
                value,
              }))}
          />
        </Form.Item>
        <Form.Item hidden={isEmpty(mediaIds)} label={dict('PLATFORM_CONFIG')} required>
          <>
            {mediaIds?.includes(MEDIA_KEY_ENUM.DOUYIN) && <DouyinConfigContent opType={opType} />}
            {mediaIds?.includes(MEDIA_KEY_ENUM.KUAISHOU) && <KuaishouConfigContent opType={opType} />}
            {mediaIds?.includes(MEDIA_KEY_ENUM.WEIXIN) && <WeixinConfigContent opType={opType} />}
            {mediaIds?.includes(MEDIA_KEY_ENUM.XIAOHONGSHU) && <XiaoHongShuConfigContent opType={opType} />}
          </>
        </Form.Item>
      </FormCard>
      {!isViewType && (
        <>
          <Blank />
          <FormCard>
            <Flex justify="center" gap={10}>
              <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
              <Button type="primary" loading={submitting} onClick={() => submitAction(PUBLISH_STATUS.DRAFT)}>
                {dict('ACTION_SAVA_DRAFT')}
              </Button>

              <Button type="primary" loading={submitting} onClick={() => submitAction(PUBLISH_STATUS.PENDING)}>
                {dict('ACTION_SUBMIT_PUBLISH')}
              </Button>
            </Flex>
          </FormCard>
        </>
      )}
    </Form>
  );
};

export default FormContent;
