import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/matrix-operation/production/const';
import { getReportDaily } from '@/services/item';
import { QueryReportParams } from '@/services/item/type';
import { DATE_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Space, Spin, Table, Typography } from '@antd';
import { useState } from 'react';
import { DetailQueryFilter } from '.';
import { downloadDyReportDetail } from '@/services/item/dy';
import { useRequest } from 'ahooks';
import { ProCard } from '@ant-design/pro-components';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

const { Link } = Typography;

export default ({ filter }: { filter: DetailQueryFilter }) => {
  const { id } = filter;
  const [downloadParams, setDownloadParams] = useState<QueryReportParams>();
  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'time', order: 'descend' } }) => {
      const downloadParams = {
        ...getSoretParams(sorter),
        itemId: id,
      };
      setDownloadParams(downloadParams);
      return getReportDaily({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [id] },
  );

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadDyReportDetail(downloadParams), {
    manual: true,
  });

  const { getBtnPermissionConfig } = usePermission();

  return (
    <ProCard boxShadow={false}>
      <Space style={{ display: 'flex', justifyContent: 'right', marginBottom: 10, marginTop: 10 }}>
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      </Space>

      <Table
        {...tableProps}
        rowKey={(record) => JSON.stringify(record)}
        columns={[
          getTableColumn({
            title: dict('DATE'),
            dataIndex: 'time',
            align: 'center',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (v) => DateUtils.format(v, DATE_FORMAT_STR),
          }),
          getTableColumn({ title: dict('PLAY_COUNT_TODAY'), dataIndex: 'playCount', sorter: true }),
          getTableColumn({ title: dict('LIKE_TODAY'), dataIndex: 'likeCount', sorter: true }),
          getTableColumn({ title: dict('SHARE_TODAY'), dataIndex: 'shareCount', sorter: true }),
          getTableColumn({ title: dict('COMMENT_TODAY'), dataIndex: 'commentCount', sorter: true }),
          getTableColumn({ title: dict('COMPLETION_RATE'), dataIndex: 'finishPlayRatio', sorter: true }),
        ]}
      />
    </ProCard>
  );
};
