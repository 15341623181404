import { _USER_KEY } from '@/constants/const';
import { checkMaterialLibrary, createMaterialLibrary } from '@/services/material_library';
import { MaterialLibraryParams } from '@/services/material_library/type';
import { UserType } from '@/services/user/type';
import { calculateFileMD5, getImgExtraInfo, getMediaInfo } from '@/utils/media';
import { post } from '@/utils/request';
import { ossUploadFile } from '@/utils/request/ossUpload';
import { getLocalStorage } from '@/utils/storage';
import { generateUUID } from '@/utils/uuid';
import { UploadOutlined } from '@ant-design/icons';
import { App, Card, Segmented, Upload, UploadProps } from '@antd';
import ImgCrop from 'antd-img-crop';
import Dragger from 'antd/es/upload/Dragger';
import { AxiosProgressEvent } from 'axios';
import { get, isEmpty } from 'lodash';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { onPreviewFileUploadImg, onPreviewFileUploadVideo, useDefaultFileList } from '../../../const';
import { Blank } from '@/components/PageCard';
import { useState } from 'react';
import { dict } from '@/hooks/useChangeLocale';

type VideoFileUploadPorops = {
  value?: any;
  onChange?: any;
  readonly?: boolean;
} & UploadProps;

type ConfigParamsType = {
  onChange?: (any) => void;
  fileType: 'image' | 'video';
};

/**
 * 文件上传的通用配置
 * @param param0
 * @returns
 */
const getFileConfig = ({ onChange, fileType }: ConfigParamsType) => {
  return {
    customRequest: async (options) => {
      const { file, onProgress, onError, onSuccess } = options;

      const fileHash = await calculateFileMD5(file); //获取文件MD5
      const { bid } = getLocalStorage(_USER_KEY) as UserType;
      const result = await checkMaterialLibrary(fileHash, bid);

      if (result.exists) {
        onSuccess?.(result);
        onChange?.(result.filePath);
      } else {
        const fileName = `${generateUUID()}.${get(file, 'name', '').split('.')[1]}`;
        try {
          const ossRes = await ossUploadFile(fileName, file as File, {
            progress: (p) => onProgress?.({ percent: p }),
          });

          //更新素材库
          await createMaterialLibrary(await convertOssRes(ossRes, fileName, file, fileType, fileHash));
          onSuccess?.(ossRes);
          onChange?.(ossRes.name);
        } catch (error) {
          onError?.(error as any);
        }
      }
    },
  } as UploadProps;
};

/**
 * 需要参考Browser.js上传文件概述  https://help.aliyun.com/zh/oss/developer-reference/overview-2
 * @param props
 * @returns
 */
export const VideoFileUpload: React.FC<VideoFileUploadPorops> = ({ onChange, value, readonly, ...props }) => {
  const { message } = App.useApp();
  const { data: defaultFileList = [], loading } = useDefaultFileList(value);
  const acceptFileTypes = ['mp4', 'webm'];

  const config: UploadProps = {
    ...getFileConfig({ onChange: (path) => onChange([path]), fileType: 'video' }),
    multiple: false,
    maxCount: 1,
    accept: acceptFileTypes.map((v) => `.${v}`).join(','),
    onRemove: () => onChange([]),
    defaultFileList: defaultFileList as any,
    onPreview: onPreviewFileUploadVideo,
    async beforeUpload(file) {
      const { size: fileSize, type } = file; // 文件大小，单位为字节
      try {
        if (fileSize > 128 * 1024 * 1024) throw dict('UPLOAD_VIDEO_MAX_SIZE_TIP'); // 128M，单位为字节
        if (!acceptFileTypes.map((v) => `video/${v}`).includes(type)) throw dict('UPLOAD_VIDEO_FILE_TIP');
        const result: any = await getMediaInfo(file);
        if (result.video_duration > 15 * 60) throw dict('UPLOAD_VIDEO_DURATION');
      } catch (error) {
        message.error(error as string);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
  };

  return loading ? (
    <Card loading />
  ) : readonly ? (
    <Upload {...config} disabled />
  ) : (
    <div id={props.id}>
      <Dragger {...config}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{dict('CLICK_TO_UPLOAD')}</p>
        <p className="ant-upload-hint">{dict('FILE_UPLOAD_REQUIRD_TIP')}</p>
      </Dragger>
    </div>
  );
};

type PictureFileUploadProps = { value?: any; onChange?: any; readonly: boolean; id?: any } & UploadProps;

/**
 * 图片上传
 * @param param0
 * @returns
 */
export const PictureFileUpload: React.FC<PictureFileUploadProps> = ({ value, onChange, readonly, ...props }) => {
  const { message } = App.useApp();
  const acceptFileTypes = ['jpeg', 'jpg', 'png'];
  const { data: defaultFileList = [], loading } = useDefaultFileList(value);
  const maxCount = 10;
  const config: UploadProps = {
    ...getFileConfig({
      onChange: (path) => {
        const values = isEmpty(value) ? [path] : [...value, path];
        if (values.length > maxCount) {
          message.warning('FILE_UPLOAD_EXCEEDED');
          return;
        }
        onChange(values);
      },
      fileType: 'image',
    }),
    multiple: false,
    maxCount,
    defaultFileList: defaultFileList as any,
    accept: acceptFileTypes.map((v) => `.${v}`).join(','),
    listType: 'picture',
    onPreview: onPreviewFileUploadImg,
    async beforeUpload(file) {
      const { size: fileSize } = file; // 文件大小，单位为字节
      try {
        if (fileSize > 20 * 1024 * 1024) throw dict('PUBLISH_SINGLE_IMG_REQUIRED');
        const { width, height } = await getImgExtraInfo(file);
        if (2 * width < height) message.warning(dict('PUBISH_IMG_WIDTH_HEIGHT_REQUIRED'));
      } catch (error) {
        message.error(error as string);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onRemove: (file) => {
      const name = file.response?.filePath || file.response?.name || file.name;
      const index = value?.findIndex((v) => v.includes(name));
      if (index > -1) {
        const arr = [...value];
        arr.splice(index, 1);
        onChange(arr);
      }

      return true;
    },
  };

  return loading ? (
    <Card loading />
  ) : readonly ? (
    <Upload {...config} {...props} listType="picture-card" disabled />
  ) : (
    <div id={props.id}>
      <ImgCrop aspect={1} aspectSlider>
        <Dragger {...config} {...props}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">{dict('CLICK_TO_UPLOAD')}</p>
          <p className="ant-upload-hint">{dict('PUBISH_IMG_REQUIRED')}</p>
        </Dragger>
      </ImgCrop>
    </div>
  );
};

/**
 * 封面上传
 * @param props
 * @returns
 */
export const PictureCoverUpload: React.FC<PictureFileUploadProps> = ({ onChange, value, readonly, ...props }) => {
  const { message } = App.useApp();
  const { data: defaultFileList = [], loading } = useDefaultFileList(value);
  const [horizontal, vertical] = [4 / 3, 3 / 4];
  const [imgCropScreen, setImgCropScreen] = useState(vertical);

  const uploadConfig: UploadProps = {
    ...getFileConfig({ onChange, fileType: 'image' }),
    maxCount: 1,
    name: 'file',
    accept: 'image/*',
    listType: 'picture',
    defaultFileList: defaultFileList as any,
    onPreview: onPreviewFileUploadImg,
    async beforeUpload(file) {
      try {
        const { width } = await getImgExtraInfo(file);
        if (width <= 324) throw dict('RESOUTION_TRTIO_REQUIRED_TIP');
      } catch (error) {
        message.error(error as string);
        return Upload.LIST_IGNORE;
      }

      return true;
    },
  };

  return loading ? (
    <Card loading />
  ) : readonly ? (
    <Upload {...uploadConfig} {...props} listType="picture-card" disabled />
  ) : (
    <div id={props.id}>
      <Segmented
        options={[
          { label: `${dict('VERTICAL_SCREEN')} 4:3`, value: vertical },
          { label: `${dict('LANDSCAPE_SCREEN')} 3:4`, value: horizontal },
        ]}
        onChange={(v) => setImgCropScreen(v as number)}
      />
      <Blank />

      <ImgCrop aspect={imgCropScreen}>
        <Dragger {...uploadConfig} {...props}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">{dict('CLICK_TO_UPLOAD')}</p>
          <p className="ant-upload-hint">{dict('COVER_IMG_SCREEN_REQUIRED')}</p>
        </Dragger>
      </ImgCrop>
    </div>
  );
};

/**
 * 本地分片上传图片
 * @deprecated 暂不使用
 * @param options UploadRequestOption
 * @returns
 */
export const localCustomRequest = (options: UploadRequestOption) => {
  const { file, onProgress, onError } = options;
  const { size: fileSize, name: filename } = file as File;
  return new Promise((resolve, reject) => {
    const chunkSize = 1024 * 1024; // 1MB 分片大小
    const totalChunks = Math.ceil(fileSize / chunkSize);
    let currentChunk = 1;

    // 递归上传分片
    function uploadChunk() {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, fileSize);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('file', chunk);
      post('/item/publish/upload', formData, {
        params: {
          filename,
          chunkNumber: currentChunk,
          phase: currentChunk == totalChunks ? 'finish' : undefined,
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100 * currentChunk) / ((progressEvent.total as number) * totalChunks),
          );
          onProgress?.({ percent });
        },
      })
        .then(() => {
          currentChunk++;
          if (currentChunk <= totalChunks) {
            // 递归上传下一个分片
            uploadChunk();
          } else {
            resolve('finished');
          }
        })
        .catch((error) => {
          onError?.(error);
          reject('upload failure');
        });
    }

    // 开始上传第一个分片
    uploadChunk();
  });
};

const convertOssRes = async (ossRes, fileName, file, fileType, fileHash) => {
  const { bid, tenantId } = getLocalStorage(_USER_KEY) as UserType;
  const { name } = ossRes;
  const { size } = file;

  const mediaRes: any = await getMediaInfo(file);

  let width = mediaRes['image_width'] || mediaRes['video_width'] || '';
  let height = mediaRes['image_height'] || mediaRes['video_height'] || '';
  if (fileType == 'image') {
    const info = await getImgExtraInfo(file);
    width = info.width;
    height = info.height;
  }

  return {
    bid,
    tenantId,
    fileHash,
    fileType,
    filePath: name,
    fileSize: size,
    fileName,
    origin: 'OSS',
    width,
    height,
    duration: mediaRes['video_duration'] || '',
    metaInfo: mediaRes,
  } as MaterialLibraryParams;
};
