import Text, { ArrLabelText } from '@/components/Form/Field/Text';
import PoiSetInput from '@/components/Form/Field/poi/RegionPoiModal';
import { OrgAccountTreeSelect } from '@/components/Form/Field/select/OrgAccountTreeSelect';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { dict } from '@/hooks/useChangeLocale';
import { XhsPublishViewPermissionOptions, columnConfig } from '@/pages/matrix-operation/publish/const';
import { ProductDetailContext } from '@/pages/matrix-operation/publish/detail/$id';
import { Card, Form, Input, Radio, TreeSelect, Typography } from '@antd';
import { trim } from 'lodash';
import { useContext } from 'react';

type PlatformConfigContentProps = {
  opType: FormOpType;
};

export const XiaoHongShuConfigContent = ({ opType }: PlatformConfigContentProps) => {
  const readonly = opType == FormOpType.VIEW;
  const form = Form.useFormInstance();
  const plartformName = 'xhsTaskDetailRequest';
  const accountId = Form.useWatch([plartformName, 'accountId'], form);
  const { activeRecordsTab } = useContext(ProductDetailContext);

  return (
    <Card title={dict('PLATFORM_XIHONGSHU')} bordered size="small" style={{ marginTop: 10 }}>
      <Form.Item
        {...columnConfig}
        label={dict('PUBLISH_ACCOUNT')}
        name={[plartformName, 'accountId']}
        required
        rules={[{ required: true }]}
      >
        {readonly ? (
          <Typography.Link onClick={activeRecordsTab}>
            {dict('SEND_ACCOUNT_NUM_TIP', { num: accountId?.length })}
          </Typography.Link>
        ) : (
          <OrgAccountTreeSelect
            multiple
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            filterMediaIds={MEDIA_KEY_ENUM.XIAOHONGSHU}
          />
        )}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('TITLE_OF_WORK')}
        name={[plartformName, 'title']}
        required
        rules={[
          {
            required: true,
            validator(_, text) {
              if (!trim(text)) return Promise.reject(dict('PLEASE_TYPE_NAME', { name: dict('TITLE_OF_WORK') }));
              return getXhsTextLength(text) > 20
                ? Promise.reject(dict('WORK_INTRODUCTION_TYPE_INPUT_TIP', { num: 20 }))
                : Promise.resolve();
            },
          },
        ]}
      >
        {readonly ? (
          <Text />
        ) : (
          <Input.TextArea placeholder={dict('PLEASE_TYPE_NAME', { name: dict('TITLE_OF_WORK') })} />
        )}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('WORK_INTRODUCTION')}
        name={[plartformName, 'caption']}
        required
        rules={[
          {
            required: true,
            max: 1000,
          },
        ]}
      >
        {readonly ? <Text /> : <Input.TextArea placeholder={dict('WORK_INTRODUCTION')} />}
      </Form.Item>

      <Form.Item
        {...columnConfig}
        name={[plartformName, '_poi']}
        label={dict('POI_POSITION')}
        tooltip={dict('POSITON_SETTING_TIP')}
      >
        <PoiSetInput
          placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('POI_POSITION') })}
          readOnly={readonly}
          mediaId={MEDIA_KEY_ENUM.XIAOHONGSHU}
        />
      </Form.Item>

      <Form.Item
        {...columnConfig}
        label={dict('PERMISSION_SETTING')}
        initialValue={opType == FormOpType.CREATE ? XhsPublishViewPermissionOptions[0].value : undefined}
        required
        name={[plartformName, 'viewPermission']}
      >
        {readonly ? (
          <ArrLabelText list={XhsPublishViewPermissionOptions} />
        ) : (
          <Radio.Group options={XhsPublishViewPermissionOptions} />
        )}
      </Form.Item>
    </Card>
  );
};

const getXhsTextLength = (text: string) => {
  const chineseCharacters = text.match(/[\u4e00-\u9fa5]/g) || [];
  // 将中文字符算作两个字符，其他字符算作一个字符
  const chineseCount = chineseCharacters.length * 2;
  const otherCount = text.length - chineseCharacters.length;
  // 总字符数
  return (chineseCount + otherCount) / 2;
};
