import { AccountAvator } from '@/components/AccountAvator';
import { EmojiImgText } from '@/components/EmojiImg';
import { dict } from '@/hooks/useChangeLocale';
import { ArrowDown, ArrowUp, Praise } from '@/icons';
import { ProdComment } from '@/services/item/type';
import { DateUtils } from '@/utils/dateUtil';
import { Divider, Space, Typography } from '@antd';
import { useBoolean } from 'ahooks';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';
const { Text } = Typography;

type CommentRecordProps = {
  comment: ProdComment;
  level: number; //level 展示层级
};

const getExpandChildRecord = (records: ProdComment[] | undefined, parentRecord?: ProdComment) => {
  let expandRecord: ProdComment[] = [];
  records?.forEach((record: ProdComment) => {
    record.parentComment = parentRecord;
    expandRecord = expandRecord.concat(record).concat(getExpandChildRecord(record.childrenList, record));
    record.childrenList = [];
  });
  return expandRecord;
};
export const CommentRecord = ({ comment, level }: CommentRecordProps) => {
  const childrenList = cloneDeep(comment.childrenList);
  const childCommentList = getExpandChildRecord(childrenList);
  const replyCommentCount = childCommentList.filter((v) => v.parentComment).length;
  const [expandRecord, { toggle: toggleExpandRecord }] = useBoolean(false);
  const offsetLeftWidth = (level - 1) * 30; //根据level控制偏移量

  return (
    <div style={{ position: 'relative', paddingLeft: offsetLeftWidth, marginTop: 5 }}>
      {/* 头像 */}
      <Space>
        <AccountAvator avaterUrl={comment.avatar} name={comment.nickName} />
      </Space>

      {/* 回复信息 */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ paddingLeft: 35, flex: 1 }}>
          <Typography.Paragraph ellipsis={{ rows: 2 }} title={comment?.content}>
            {comment.parentComment ? (
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Text> {dict('REPLY')}</Text>{' '}
                <Text style={{ paddingLeft: 5 }}> {comment.parentComment.nickName}：</Text>
              </div>
            ) : null}
            <EmojiImgText content={comment?.content} style={{ color: '#333' }} />
            <div
              style={{ color: '#666', marginTop: 3 }}
              title={`${dict('COMMENT_TIME')}：${DateUtils.formatDateTime(comment?.createTime)}`}
            >
              {DateUtils.toFriendlyTime(comment?.createTime)}
            </div>
          </Typography.Paragraph>
        </div>

        {/* 右边按钮 */}
        <Space style={{ display: 'flex', width: '30vw' }}>
          <Divider type="vertical" />
          <Text title={dict('LIVE_INDICATOR_LIKE_UV')}>
            <Praise style={{ cursor: 'auto' }} />
            &nbsp;
            {comment.diggCount}
          </Text>
        </Space>
      </div>

      {isEmpty(comment.childrenList) ? null : (
        <div style={{ marginTop: 10 }}>
          {childCommentList
            .map((comment, index) => {
              if (comment.parentComment && !expandRecord) return null;
              //递归加载子层级
              return <CommentRecord key={index} comment={comment} level={level + 1} />;
            })
            .filter((v) => v)}

          {replyCommentCount ? (
            <Text
              onClick={toggleExpandRecord}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 60,
                color: '#999',
                marginTop: 10,
              }}
            >
              {replyCommentCount ? (
                expandRecord ? (
                  <>
                    {dict('HIDE_ALL_REPLIES')}
                    <ArrowUp />
                  </>
                ) : (
                  <>
                    {dict('EXPAND_NUM_REPLIES', { num: replyCommentCount })} <ArrowDown />
                  </>
                )
              ) : null}
            </Text>
          ) : null}
        </div>
      )}
    </div>
  );
};
